import React from "react";
import AppLayout from "./layout";
import { useHistory } from "react-router-dom";
import { set } from "ramda";

export default function Idle() {
  const history = useHistory();

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      // 👇️ redirects to an external URL
      window.location.replace("/en/confirm");
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <AppLayout title={""} style={{ padding: 0 }}>
      <header>
        <h1>Preparing order</h1>
        <h2>Please wait a few moments</h2>
      </header>
      <span className="content">
        
        <img
            className="loading"
            src={process.env.PUBLIC_URL + "/lekker/oval.svg"}
            alt="lekkerland"
            style={{height:344}}
          />
      </span>
    </AppLayout>
  );
}
