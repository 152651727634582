import AppLayout from "./layout";
import { useHistory } from "react-router-dom";
import BuybyeService from "../services/buybye-service"
import React, { useEffect, useRef } from "react";

export default function Idle() {
  const history = useHistory();

  React.useEffect(() => {
    BuybyeService.setLanguage({ machine: { code: "0001" }, language: "de" })
  })



  return (
    <AppLayout title={""} style={{ padding: 0 }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          src={process.env.PUBLIC_URL + "/lekker/logo.svg"}
          alt="Open Cabinet"
          style={{
            width: "10%",
            paddingBlock: "50px",
            alignSelf: "center",
            display: "flex",
          }}
        ></img>
      </div>
      <div className="image">
        {/* <img
          className="logo"
          src={process.env.PUBLIC_URL + "/lekker/animation.gif"}
          alt="lekkerland"
        />  */}
        <video loop autoPlay muted>
          <source src={process.env.PUBLIC_URL + "/lekker/00-complete_1_1.mp4"} type="video/mp4" />
        </video>
      </div>
      <div className="container">
        <a href="/de/card?lang_code=de">
          <button className="button" style={{ alignSelf: "center" }}>
            <img src={process.env.PUBLIC_URL + "/lekker/DE.svg"} alt="" />
            <div className="button-text">Einkaufen</div>
            <div style={{ width: "84px", height: "60px", }}></div>
          </button>
        </a>
        <a href="/en/card?lang_code=en">
          <button className="button" style={{ alignSelf: "center" }}>
            <img src={process.env.PUBLIC_URL + "/lekker/GB.svg"} alt="" />
            <div className="button-text">Start Shopping</div>
            <div style={{ width: "84px", height: "60px", }}></div>
          </button>
        </a>
      </div>
    </AppLayout>
  );
}
