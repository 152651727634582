import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch, Redirect } from "react-router";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import { createBrowserHistory } from "history";
import Blank from "./pages/blank";
import Idle from "./pages/idle";



import OpenDE from "./pages/lekOpenDE";
import CardDE from "./pages/lekCardDE";
import CloseDE from "./pages/lekCloseDE";
import ConfirmDE from "./pages/lekConfirmDE";
import GrabDE from "./pages/lekGrabDE";
import LoadingDE from "./pages/lekLoadingDE";


import OpenEN from "./pages/lekOpenEN";
import CardEN from "./pages/lekCardEN";
import CloseEN from "./pages/lekCloseEN";
import ConfirmEN from "./pages/lekConfirmEN";
import GrabEN from "./pages/lekGrabEN";
import LoadingEN from "./pages/lekLoadingEN";




import WaitDoor from "./pages/waitDoor";
import DoorOpen from "./pages/doorOpen";
import ProcessingPayment from "./pages/processingPayment";
import PurschaseValue from "./pages/purschaseValue";

import CardProblem from "./pages/cardProblem";
import TechProblem from "./pages/techProblem";
import Maintenance from "./pages/maintenance";

import * as serviceWorker from "./serviceWorker";
import App from "./App";
import Wrapper from "./pages/Wrapper";
import AuthService from "./services/auth-service";

function PrivateRoute({ component: Component, ...rest }) {
  const user = AuthService.getCurrentUser();
  let _render;
  if (user && user._id) {
    _render = (props) => {
      return <Component {...props} />;
    };
  } else {
    _render = (props) => {
      return <Redirect to="/signin" />;
    };
  }
  return <Route {...rest} render={(props) => _render(props)} />;
}
var hist = createBrowserHistory();
ReactDOM.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />

    <Router history={hist}>
      <App>
        <Wrapper>
          <Switch>
            <Route path="/blank" component={Blank} />
            <Route path="/en/loading" component={LoadingEN} />
            <Route path="/en/grab" component={GrabEN} />
            <Route path="/en/confirm" component={ConfirmEN} />
            <Route path="/en/close" component={CloseEN} />
            <Route path="/en/open" component={OpenEN} />
            <Route path="/en/card" component={CardEN} />
            <Route path="/de/loading" component={LoadingDE} />
            <Route path="/de/grab" component={GrabDE} />
            <Route path="/de/confirm" component={ConfirmDE} />
            <Route path="/de/close" component={CloseDE} />
            <Route path="/de/open" component={OpenDE} />
            <Route path="/de/card" component={CardDE} />
            <Route path="/de/home" component={Idle} />
            <Route path="/" component={Idle} />
          </Switch>
        </Wrapper>
      </App>
    </Router>
  </ThemeProvider>,
  document.querySelector("#root")
);
serviceWorker.unregister();
